@import 'shared/styles/colors.scss';

.widget-equipment-statistic tr{
    height: 50px;
    color: $colorSecondary_6;
    font-size:0.88em;
    width: 100%;
}
.widget-equipment-statistic tr td:last-child {
    text-align: right;
    font-weight: bolder;
    font-size: 1.17em;

}
