$sizeXXS:8px;
$sizeXS	:12px;
$sizeSM	:14px;
$size   :16px;
$sizeMD	:20px;
$sizeLG	:24px;
$sizeXL	:32px;
$sizeXXL:48px;
// $sizeXXS:1em;
// $sizeXS	:2em;
// $sizeSM	:3em;
// $size   :4em;
// $sizeMD	:5em;
// $sizeLG	:6em;
// $sizeXL	:7em;
// $sizeXXL:8em;

