@import "shared/styles/colors.scss";
@import "shared/styles/styleGlobal.scss";

#tablePaymentTypesGlobalByPeriod {
    border-collapse: collapse;
}

#tablePaymentTypesGlobalByPeriod tr td, #tablePaymentTypesGlobalByPeriod tr th{
    height: 2.4em;
}

#tablePaymentTypesGlobalByPeriod thead tr th {
    // background-color: $table-stripped-even-bg-color;
    // color: $colorNeutral_5;
    //border-color: red ;
}

#tablePaymentTypesGlobalByPeriod thead tr th:first-child {
    border: none;
    background-color: transparent;
}

#tablePaymentTypesGlobalByPeriod tbody tr td:first-child {
    text-align: right;
    font-weight: 600;
}

#tablePaymentTypesGlobalByPeriod tbody tr.row-turnover-real,
#tablePaymentTypesGlobalByPeriod tbody tr.row-turnover-total {
    color: $colorNeutral_1
}

#tablePaymentTypesGlobalByPeriod tbody tr.row-turnover-real td:first-child,
#tablePaymentTypesGlobalByPeriod tbody tr.row-turnover-total td:first-child {
    text-align: center;
}

#tablePaymentTypesGlobalByPeriod tbody tr.row-turnover-real td {
    background-color: $colorPrimary_6;
    font-weight: 600;
    height: 3em;
}

#tablePaymentTypesGlobalByPeriod tbody tr.row-turnover-total td {
    background-color: $colorPrimary_8;
    font-weight: 700;
    height: 3em;
}
