.chevron-down {
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  display: inline-block;
  transform: rotate(90deg);
}

.chevron-down.expanded {
  transform: rotate(270deg);
}

.saleDetailDiv {
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 10px #00000047;
  background-color: #ffff;
  min-height: 150px;
  width: 100%;
  left: 0;
  padding: 0 0 4px 10px
}
