.promoCardsContainer{
    display: flex;
    flex-direction: row;
    
}

.addPromoButton{
    width: 233px;
    height: 38px;
    font-size: 13px;
    text-align: left;
    font-weight: bold;  
    line-height: 16px;  
    border-radius: 19px;
    box-shadow: 0px 2px 6px #00000042;
    margin-top: 56px;
}
.iconCard{
  
}
.cardNumber{
    font-weight: bold;
    font-size: 85px;
    line-height: 105px;
    text-align: center;
    color:#fff
}
.cardTitle{
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color:#fff;
    margin-top: 17px;
    margin-bottom: 31px;
}
.cardLabel{
    text-decoration: underline;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color:#fff
}