.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.ring {
  width: 200px;
  height: 200px;
  border: 10px solid #d3e6fc2e;
  border-radius: 50%;
  position: relative;
  animation: spin 1s linear infinite;
}

.ring-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center bottom;
}

.semi-ring {
  width: 100%;
  height: 100%;
  border: 10px solid #005dff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center bottom;
  clip: rect(0px, 100px, 100px, 0px); /* Clip the upper half */
  animation:  spinInPlace 2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinInPlace {
  0% {
    transform: rotate(0deg);
  }
 
}
