.badgeCardDate {
    margin-bottom: 17px;
    line-height: 21px;
}

.badgeAmountCA {
    margin-top: 10px
}

.badgeLabel {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 5px;
    color: #1B8ADC;
    font-weight: 500;
}

.badgeAmount {
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 15px
}