@import 'shared/styles/styleGlobal.scss';

.pagination-select-label {
  text-align: left;
  font-weight: $select-label-font-weight;
  font-size: 11px;
  color: $select-color;
  // margin: $select-label-margin;
}
.pagination-container {
  display: flex;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  align-items: flex-end;
}

.pagination .page-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-link {
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 5px;
}

.prec-btn,
.next-btn {
  margin-right: 2%;
  .btn{
    padding:4px 10px;
  }
}
.pagination .page-link:hover {
  background-color: #e0e0e0;
}

.pagination .page-link:focus {
  box-shadow: none;
}

.pagination .page-link:active {
  background-color: #c0c0c0;
}

.pagination .page-item.active .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
  background-color: #fff;
  border-color: #ddd;
}

.pagination .page-item.disabled .page-link:hover {
  cursor: not-allowed;
  background-color: #fff;
}
.pagination .form-control::-webkit-outer-spin-button,
.pagination .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pagination .form-control {
  width: 30px;
  height: 25px;
  border: 1px solid #a5c9ff;
  padding: 5px;
  background-color: #f7f9fc;
  text-align: center;
}
.pagination .input-group {
  width: 150px;
  margin-left: 10px;
}
.pagination .pagination-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // background-color: red;
}

.pagination .ml-3 {
  margin-left: 20px;
}


.pagination .form-select {
  border: 1px solid $select-color;
  width: 70px;
  height: 25px;
  border-radius: 5px;
  color: $select-color;
}

.pagination .form-select::after {
  content: 'x';
  color: blue;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}
.pagination .btn-primary {
  margin-left: 5px;
}

.pagination .pagination-ellipsis{
  text-align: center;
  font-weight: bold;
  padding-right: 0.5em;
}