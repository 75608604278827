 @import 'shared/styles/styleGlobal.scss';
// .date-picker > input[type='text'] {
//   all: unset;
//   overflow: hidden;
//   border: $select-border;
//   border-radius: $select-border-radius;
//   background-color: $select-background-color;
 
//   cursor: pointer;
//   margin: $select-margin;
//   height: $select-height;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
// .date-picker { position: relative;
// }
// .date-picker .calendar {
//   position: absolute;
//   top: 75px;
//   left: 0;
//   width: 250px;
//   background-color: #fff;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   z-index: 999;
//   padding: 10px;
// }
.date-picker {
  position: relative;
  .select-date{
    padding: 10px;
      width: 170px;
      //border: 1px solid #ccc;
      //border-radius: 4px;
      cursor: pointer;
      border: $select-border;
      border-radius: $select-border-radius;
      background-color: $select-background-color;
      height: $select-height;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $select-color;
  }
input:active {
    /* Reset the styles here */
    /* For example, remove any background color and border */
    background-color: unset;
    border: unset;
  }
  input {
   
   all: unset;
   width: 100%;
  }
  .calendar {
    position: absolute;
    top: 75px;
    left: 0;
    width: 250px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 10px;
  }

    .select {
      border: $select-border;
      border-radius: $select-border-radius;
      background-color: $select-background-color;
      position: relative;
      cursor: pointer;
      // margin: $select-margin;
      height: $select-height;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
    }
}