.loginContainer {
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}

.loginMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
}

.loginPresentationContainer {
  background-color: var(--primary-color);
  width: 371px;
  min-width: 371px;
  height: 561px;
  min-height: 561px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 9px 0 0 9px;
  padding: 27px;
}

.loginPresentation { 
  width: 300px;
  height: 155px;
  font-size: 12pt;
  color: #FFFFFF;
  line-height: 22px;
  text-align: justify;
  margin: 24px 0 40px 0;
}

.loginHelp {
  width: 278px;
  height: 72px;
  font-size: 10pt;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  margin: 15px 0 10px 0;
}

.loginHelpIcon {
  width: 50px;
  height: 50px;
}

.loginPhoneNumber {
  width: 268px;
  height: 41px;
  font-size: 18pt;
  color: #FFFFFF;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

.loginFormContainer {
  width: 371px;
  min-width: 371px;
  height: 561px;
  min-height: 561px;
  background-color: #fff;
  border-radius: 0 9px 9px 0;
  padding: 5px 20px 5px 20px;
}

.loginFormLanguageSelector {
 height:40px;
}

.loginFormLanguageSelector .language-label {
  color: #000;
}

.loginForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

.keyIcon {
  height: 50px;
  margin: 7px 0 30px 0;
}

.loginTitle {
  color: var(--neutral-color);
  font-size: 12pt;
  line-height: 37px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.rememberMeCheckbox {
  // color: #5CA9DD;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 10px;
  text-align: left;
  vertical-align: middle;
}

.loginResetPassword {
  // color: #41B9FF;
  color: var(--secondary-color);
  text-decoration: underline;
  font-size: 9pt;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 20px;
}

.loginResetPassword:hover {
  // color: #E53D3A;
  color: var(--error-color);
}

.loginButton {
  background-color: var(--secondary-color);
  // border: 1px solid #FFFFFF87;
  border: 1px solid  var(--neutral-color);
  width: 300px;
  height: 50px;
  border-radius: 18px;
  cursor: pointer;
  color: #ffff;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-top: 20px;
}

.loginFooter {
  // background-color: #E53D3A;
  background-color: var(--error-color);
  margin-top: 10px;
  border-radius: 9px;
  width: 742px;
  height: 147px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
}

.loginFooterTitle {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #ffff;
  text-transform: uppercase;
  width: 354px;
  margin-bottom: 18px;
}

.loginFooterUL {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  color: #ffff;
  text-transform: uppercase;
}

.loginFooterUL>li::before {
  content: "✓ ";
}

.loginFooterCallToActionButton {
  background-color: var(--secondary-color);
  // border: 1px solid #FFFFFF87;
  border: 1px solid  var(--neutral-color);
  width: 167px;
  height: 35px;
  border-radius: 18px;
  cursor: pointer;
  color: #ffff;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  text-transform: uppercase;
}

.loginFooterCallToActionButton:hover, .loginButton:hover {
  background-color: #ffff;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  font-weight: bold;
}

.loginFooterCallToActionLabel {
  color: #ffff;
  text-align: center;
  line-height: 19px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 00;
  margin-bottom: 14px;
}

.loginFooterCallToActionLabel>div {
  margin-bottom: 2px;
}

// .loginFooterImage{
//   background-color: var(--secondary-color);
// }