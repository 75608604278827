
@import 'ck_commun/src/app/modUtils/style/reset.scss';

body{
    font-family: Poppins-Regular ;
    font-size: 14px;


}

h1              { font-size: 2rem; margin: .67rem 0 }//28px
h2              { font-size: 1.5rem; margin: .75rem 0 }//21px
h3              { font-size: 1.17rem; margin: .83rem 0 } //16px
h5              { font-size: .83rem; margin: 1.5rem 0 }//12px
h6              { font-size: .75rem; margin: 1.67rem 0 }//10px
h1, h2, h3, h4,
h5, h6          { font-weight: bolder }

// /* Define color variables */
// $color-primary: #ff0000; /* Red */
// $color-secondary: #00ff00; /* Green */
// $color-text: #ffffff; /* White */

// /* Define heading styles with color classes */
// .heading {
//   &.primary {
//     color: $color-primary;
//   }

//   &.secondary {
//     color: $color-secondary;
//   }

//   &.text {
//     color: $color-text;
//   }
// }

// .headline{
//     &-primary{

//     }
//     &-secondary{

//     }
// }
// .title{
//     &-primary{}
//     &-secondary{}
// }

// /* Apply styles to heading elements */
//  h1, h2, h3, h4,
// h5, h6  {
//   @extend .heading;

// }

