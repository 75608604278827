@import 'shared/styles/colors.scss';

.userName {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  margin: 10px 0 10px 0;
}

.headerDivider {
  border-left:1.5px solid $colorPrimary_6; 
  height:45px;
}

#meteoInfoRow:nth-child(2n){
  background-color:  $colorNeutral_3;
}