.manageModalContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.eqButton{
    background-color: #1B8ADD;
    color:white;
    width:100%;
    border-radius: 10px;
    height: 40px;
    margin:0 5px 0 5px;
}