@import "colors";
@import "size";
/* colors */
$disabled-bg-color: #c2ddfc;
/* Define font families and sizes */
$font-size-base: $sizeSM;
$h1-font-size: $sizeXXL;
$h2-font-size: $sizeXL;
$h3-font-size: $sizeLG;
$h4-font-size: $sizeMD;
$h5-font-size: $size;
$h6-font-size: $sizeSM;

/* Border, Border radius and Box shadow */
$box-shadow-color: $colorNeutral_4;
$border-radius-base: 10px;

/* Menu */
$menu-bg-color: $colorPrimary_6; 
$menu-hover-bg-color: darken($colorPrimary_6, 7%); 
$menu-active-left-border-color: $colorError_6;
/* Sider */
$sider-bg-color: $colorPrimary_6; 


/* Modal */
$modal-overlay-bg: $colorNeutral_10; 
$modal-overlay-opacity: 75%;
$modal-bg-color: $colorNeutral_1; 
$modal-box-shadow: 0px 3px 6px $box-shadow-color;
$modal-border-radius: $border-radius-base;
$modal-max-width: 95%;
$modal-max-height: 95%;
$modal-padding: 20px 35px;
$modal-zIndex: 8888;
$modal-title-color: $colorSecondary_6;
$modal-title-font-weight: bold;
$modal-title-font-size: $sizeLG;
$modal-title-line-height: $sizeXL;
$modal-title-margin-bottom: 25px;
$modal-title-text-transform: uppercase;
$modal-title-text-align: center;
$modal-close-color: $colorSecondary_6;
$modal-close-size: $sizeXL + 8;

/* Card */
$card-box-shadow: 0px 3px 16px $box-shadow-color;
$card-border-radius: $border-radius-base ;
$card-bg-color: $colorNeutral_1;
$card-margin: 5px 10px;
$card-text-align: center;
$card-border: 1px solid $colorPrimary_4; // #A5C9FF
$card-header-bg-color: $colorSecondary_6;
$card-header-border-radius: $card-border-radius $card-border-radius 0 0;
$card-header-padding: 5px; 
$card-title-font-size: $sizeXS;
$card-title-line-height: $card-title-font-size;
$card-title-color:  $colorNeutral_1;
$card-body-padding: 6px 10px 6px 10px;

/* Tabs */
$tabs-header-bg-color: $colorPrimary_6;
$tabs-header-element-margin-right:5px;
$tabs-header-element-padding: 5px;
$tabs-header-element-border-radius:  5px 5px 0 0;
$tabs-header-height: 40px;
$tabs-header-label-color: $colorNeutral_1;
$tabs-header-label-text-transform: uppercase;
$tabs-header-label-font-size:$sizeXS;
$tabs-header-label-font-weight:500;
$tabs-header-label-line-height: $tabs-header-label-font-size;
$tabs-content-padding: 10px 0;
$tabs-content-bg-color: $colorNeutral_1;
$tabs-content-border: 1px solid $colorSecondary_6;
$tabs-content-border-radius: 0 0 5px 5px;

/* Select */
$select-color: $colorPrimary_6;
$select-label-font-size: $sizeSM;
$select-label-font-weight: bold;
$select-label-margin: 0 0 5px 10px;
$select-border: 1px solid $colorPrimary_6;
$select-background-color: $colorNeutral_1;
$select-margin: 0 10px 0 10px ;
$select-height:40px;
$select-border-radius: 5px;
$select-disabled-bg-color: $disabled-bg-color;

/* Button */
$button-border-radius: 3px;
$button-border-width: 1px;
$button-border-type: solid;
$button-font-weight: "bold";
$button-font-size: $sizeSM;
$button-cursor: pointer;
$button-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
/*******/
$button-border-color: $colorPrimary_6;
$button-color: $colorNeutral_1;
$button-bg-color: $colorPrimary_6;
/****hover*/
$button-hover-bg-color: darken($colorPrimary_6, 10%);
/* button primary */
$button-primary-border-color: $colorPrimary_6;
$button-primary-color: $colorNeutral_1;
$button-primary-bg-color: $colorPrimary_6;
/* primary hover */
$button-primary-hover-border-color: $colorPrimary_6;
$button-primary-hover-color: $colorNeutral_1;
$button-primary-hover-bg-color: darken($colorPrimary_6, 10%);
/* button default */
$button-default-border-color: $colorPrimary_6;
$button-default-color:  $colorPrimary_6;
$button-default-bg-color: $colorNeutral_1;
/* default hover */
$button-default-hover-border-color: $colorPrimary_6;
$button-default-hover-color: $colorNeutral_1;
$button-default-hover-bg-color: $colorPrimary_6;
/* button text */
$button-text-border-color: transparent;
$button-text-color: $colorPrimary_6;
$button-text-bg-color: transparent;
/* text hover */
$button-text-hover-border-color: transparent;
$button-text-hover-color: darken($colorPrimary_6, 10%);
$button-text-hover-bg-color: transparent;
// $button-disabled-opacity: 0.5;

/*table*/
$table-header-bg-color:$colorPrimary_6;
$table-header-border: solid 1px #FCFCFC;
$table-cell-align: center;
$table-header-text-transform: uppercase;
$table-header-font-weight: bold;
$table-cell-font-size:$sizeXS - 1;

$table-header-color: $colorNeutral_1;
$table-row-height: $sizeXL ; //40px
$table-cell-font-weight: 400;
$table-cell-border: solid 1px #00CFF933;
$table-stripped-even-bg-color:#EFF7FC;
$table-row-bg-color: white;
$table-row-bg-color-hover: darken($table-stripped-even-bg-color, 5%);

// /* Define spacing and sizing */
// $spacing-unit: $sizeXXS ;
// $button-padding: $spacing-unit 2*$spacing-unit; 
 
// /* Define border and shadow styles */
// $border-radius: 3px;
// $border-radius-large: 12px;
// $box-shadow: 0px 3px 16px #0A4B6D14;

// /* Define form styles */
// $input-height: 40px;

// $input-border-radius: $border-radius;
// $input-border-color: #ced4da;
// $input-background-color: #fff;

// /* Define button styles */
// $button-border-radius: $border-radius;
// $button-border-width: 2px;
// $button-border-color: $primary-color;
// $button-color: #fff;
// $button-bg-color: $primary-color;
// $button-hover-bg-color: darken($primary-color, 10%);
// $button-disabled-opacity: 0.5;

// /* Define navigation styles */
// $menu-item-padding: $spacing-unit 2*$spacing-unit;
// $menu-item-border-radius: $border-radius;

// /* Define grid breakpoints */
// $sm-breakpoint: 576px;
// $md-breakpoint: 768px;
// $lg-breakpoint: 992px;
// $xl-breakpoint: 1200px;

