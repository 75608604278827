.badgeInfoBox {
  width: 280px;
  min-width: 280px;
  height: 127px;
  min-height: 127px;
  margin: 10px 35px 10px 35px
}

.badgeInfoBoxIcon {
  width: 67px;
  min-width: 67px;
  height: 127px;
  min-height: 127px;
}

.badgeInfoBoxContent {
  width: 213px;
  min-width: 213px;
  height: 127px;
  min-height: 127px;
}

.badgeInfoBoxAmount {
  font-size: 25px;
  line-height: 25px;
  margin-top: 5px
}