.marketingInfoBox {
  width: 350;
  min-width: 350;
  height: 136px;
  min-height: 136px;
  margin: 20px 40px 10px 40px
}

.marketingInfoBoxIcon {
  width: 80px;
  min-width: 80px;
  height: 136px;
  min-height: 136px;
}

.marketingInfoBoxContent {
  width: 270px;
  min-width: 270px;
  height: 136px;
  min-height: 136px;
}

.marketingInfoBoxAmount {
  font-size: 38px;
  line-height: 38px;
}