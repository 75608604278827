.ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    /* color: #1B8ADD; */
    color: var(--primary-color);
    font-size: 12px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    /* border: 1px solid #1B8ADD; */
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    transition: all 0.3s;
  }
  