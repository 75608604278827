.APP_table>tr>.badgeTransactionTableHeaderAmount{
    background-color: #40ADFF;
}

.APP_table>tr>.badgeTransactionTableAmountBalance,
.APP_table>tr>:nth-child(2n+1)>.badgeTransactionTableAmountBalance
{
    border: 0.5px solid #FFFFFF;
    background-color: #40ADFF;
    color: #ffff;
    
}