.language-select{
    float: right;
    width: fit-content;
}


.country-flag{
   width:fit-content;
   margin:0 5px;
    img{
        width:25px;
    }
    .language-label{
        font-size: 9px;
        font-weight: 500;
        color: #ffff;
    }
}
.language-switcher{
.language-expand-icon{
    transform: rotate(90deg);
}}
.language-expand-icon.expanded  {
    transform: rotate(-90deg);
  }