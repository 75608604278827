 .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    /* color: #1b8add; */
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 500;
}
.ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    margin-bottom: 6px;
    vertical-align: top;
}
.ant-form-item-label > label::after {
    content: ' ';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
  .ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    margin-right: 20px;
  }
