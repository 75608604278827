.sidebarHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 13px 13px 30px 13px;
}

.sideBarGroupName {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
  text-align: center;
}

.supportModalContainer {
  bottom: 0;
}

