@import "shared/styles/styleGlobal.scss";

.modal {
    position: fixed;
    z-index: $modal-zIndex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
}

.modal.is-open {
    display: flex;
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-overlay-bg;
    opacity: $modal-overlay-opacity;
}

.modal-content {
    position: relative;
    background-color: $modal-bg-color;
    padding: $modal-padding;
    box-shadow: $modal-box-shadow;
    border-radius: $modal-border-radius;
    max-width: $modal-max-width;
    max-height: $modal-max-height;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-title {
    color: $modal-title-color;
    font-weight: $modal-title-font-weight;
    font-size: $modal-title-font-size;
    line-height: $modal-title-line-height;
    word-wrap: break-word;
    text-transform: $modal-title-text-transform;
    text-align: $modal-title-text-align;
    margin-bottom: $modal-title-margin-bottom;
}

.modal-close {
    position: absolute;
    color: $modal-close-color;
    top: -0.5rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: $modal-close-size;
    cursor: pointer;
}

.modal-footer {
    margin-top: 10px;
}