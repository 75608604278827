.app-name-container {

    margin-bottom: 1.5em;
}

.app-name {
    color: #fff;
    margin-left:0.5em;
    text-transform: uppercase;
}

.app-logo {
    width: 3em;
    height: auto;
}