
@import "shared/styles/styleGlobal.scss";
.creditBadgeButton {
  cursor: pointer;
  padding: 0 10px 0 10px;
  color: #7d7d7d;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.creditBadgeButtonLabel {
  margin-top: 3px;
  font-size: 10px;
  line-height: 11px;
  font-weight: 600;
}

.credit-support-form{
    .ant-form-item {
        label {
            color: #338EF5 !important;
            font-size: 14px !important;
            font-weight: bold !important;
            text-transform: capitalize !important;
        }
    }
}

.credit-support-form{
    .input .input-wrapper {
        border-radius: 5px;
        color: #1B8ADD;
        text-align: left;
        vertical-align: middle;
        padding: 5px;
        overflow: hidden;
        border: solid 1px #338EF5;
        background-color: #F7F9FC;
        margin-bottom: 0;

    }

    .ant-form {
        .ant-form-item {
            label {
                color: #338EF5 !important;
                font-size: 14px !important;
                font-weight: bold !important;
                text-transform: capitalize !important;
            }
        }
    }

    .ant-picker {
        height: $select-height;
        border: $select-border;
        border-radius: $select-border-radius;
        background-color: #F7F9FC;
        color: $select-color;
    }
}