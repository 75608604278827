.site-panel-header-title{
    width: fit-content;
}
.siteManageInfos{
    color: #013D7B;
    padding: 20px;
    margin-bottom: 10px;
}

.button{
    display: block;
    background-color: #1B8ADD ;
    color:white;
    width:100%;
    border-radius: 10px;
    height: 40px;
}
.siteName{
    color:#ffff;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
}