@import "shared/styles/styleGlobal.scss";
ul.action-button-menu-elements {
    margin: 0;
    li{
        cursor: pointer;
        color: var(--secondary-color);
        margin: 0.5em 0;
        padding:0 0.5em;
    }
    li *{
        margin: 0;     
    }
    li:hover {
        background-color: #ECF6FC
    }
    .disabled{
        color:#c3c3c3;
        background-color: #f5f5f5;
        cursor: not-allowed;
    }
}

.ant-popover.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight{
    padding-top: 0 !important;
}