@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../src/shared/styles/colors.scss";
@import "../src/shared/styles/input.css";
@import "../src/shared/styles/form.css";
@import "ck_commun/src/app/modUtils/style/flex.scss";
@import "../src/shared/styles/fonts.scss";
@import "../src/shared/styles/styleGlobal.scss";


:root {
    /* Couleur du site LAUNDRY */
    --primary-color: #008DD2;
    --secondary-color:#0078FF;
    --thirty-color: #FFBB00;
    --accent-color: #40adff;

    --success-color: #00D423;
    --error-color:#FF0000;
    --neutral-color: #9F9F9F;
  }

#root {
  height: 100%;
  
}
.block{
  width:100%
}

.info-container{
  border: 1px solid $colorSecondary_6;
  border-radius: $border-radius-base;
  background-color: $colorSecondary_3; 
  
}
.filters>* {
  margin-right: 10px;
  margin-bottom: 15px;
}

/* <-- texte qui précède un tableau --> */
.table-pre-text {
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  color: #354052;
  font-size: 14px;
  line-height: 18px;
}


/* <-- infoBox --> */
.info-box {
  box-shadow: 0px 3px 6px #2c282836;
  border: 1px solid #ececec;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.info-box-icon{
  border-radius: 10px 0px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-box-content {
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 14px 19px 12px 5px;
}

.info-box-label {
  text-align: right;
  font-size: 15px;
  line-height: 21px;
  color: #8898aa;
  text-transform: uppercase;
}

.info-box-amount {
  color: #4d4f5c;
  font-weight: bold;
  text-align: left;
}

.info-box-comment {
  text-align: right;
  font-size: 12px;
  line-height: 21px;
  color: #000;
  font-weight: 500;
  margin-top: 11px
}
/* </-- infoBox -->*/

.APP_h1_text {
  text-transform: uppercase;
  font-size: 17px;
  line-height: 24px;
  color:#000000;
  font-weight: bold;
  margin: 20px ;
  text-align: center;
}

/*statistics*/
.chart-container  {
  background-color: #D3E6FC2E;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;


.chart-title {
  color: #338EF5;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px
};
};

/*scrollbar*/
/* Styles pour les navigateurs basés sur Webkit (Chrome et Safari) */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #dbdada;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c5c4c4;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Styles pour Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #dbdada #f1f1f1;
}

/* Styles pour la barre de défilement elle-même (track) dans Firefox */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Styles pour la poignée de la barre de défilement (thumb) dans Firefox */
*::-webkit-scrollbar-thumb {
  background-color: #dbdada;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #c5c4c4;
}
.cursor-pointer{
  cursor: pointer;
}
