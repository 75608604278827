@import "shared/styles/styleGlobal.scss";
.layout {
  display: flex;

}

.layout .content {
  width: 100%;
  padding: 10px;
  height: 100vh;
  overflow: auto;
  flex-wrap: wrap;
}

.layout .sider {
  height: 100vh;
  top: 0;
  bottom: 0;
  background-color: $sider-bg-color;
}

.trigger {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 12px #00000073;
  z-index: 101;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;


  &-left-position {
    margin: 14px 0 0 -17px;
  }

  &-right-position {
    margin: 14px -17px 0 0px;
  }

  &-reverse {
    transform: scaleX(-1);
  }
}


// /* Media query for collapsing sidebars when the screen is half */
// @media screen and (max-width: 600px) {
//   .layout {
//     flex-direction: column;
//   }

//   .layout-sider-right {
//     display: none;
//   }
// }