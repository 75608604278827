@import "shared/styles/colors.scss";
.activityCardDate {
    height: 40px;
    margin-bottom: 11px;
    line-height: 21px;
}

.activityPaymentModesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #00235D;
    height: 194px;
}

.activityPaymentModesLabel {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 5px;
}

.activityPaymentModesTable{
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.activityPaymentLabel {
    font-size: 13px;
    text-align: left;
    padding: 0 5px 0 5px;
}

.activityPaymentAmount {
    text-align: right;
    font-weight: bold;
}

.activityEvolutionContainer {
   /* display: flex;
    flex-direction: column;
    justify-content: center;  */
}

.activityEvolutionDate {
    line-height: 17px;
    height: 53px;
    margin-bottom: 10px;
    
}

.activityEvolutionNumbers {
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:2px;
   
}

.activityEvolutionPercent {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 18px;
    font-family: Poppins-ExtraBold;
    text-align: right;
    align-items: center;
}

.activityEvolutionDecrease {
    color: $colorError_4;
    padding-right: 5px;
}

.activityEvolutionStable {
    color:#BFBCBC;
    padding-right: 5px;
}

.activityEvolutionIncrease {
    color: $colorSuccess_6;
    padding-right: 5px;
}

.activityCardChart {
    padding-top: 10px;
    height: max-content;
    box-shadow: 0px 3px 16px #0A4B6D14;
    border: 1px solid #A5C9FF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.activityEvolutionNumbers .activityNoDataComment{
    color:$colorError_4;
    text-align: center;
    width: 100%;
}