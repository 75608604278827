.siteUpdateInfos{
    padding:20px  40px 20px 40px;
}
.button{
    display: block;
    background-color: #1B8ADD ;
    color:white;
    width:100%;
    border-radius: 10px;
    height: 40px;
}