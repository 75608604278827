.popover {
    position: absolute;
    z-index: 999;
    display: block;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 8px;
    width: max-content;
}

.popover-arrow {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
}

.popover.topLeft {
    top: 10px;
    left: 10px;
}

.popover.topRight {
    top: 10px;
    right: 10px;
}

.popover.bottomLeft {
    left: 10px;
}

.popover.bottomRight {
    right: 10px;
}

.popover.middle {
    top: 50%;
    left: 50%
}