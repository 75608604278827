.devicesDetailedHistoryDeviceNameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.devicesDetailedHistoryDeviceName {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.devicesDetailedHistoryDeviceName > div {
  padding-left: 15px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 0.72px;
  color: #1B8ADD ;
  text-align: left;
}

.devicesDetailedHistoryDeviceInfos {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0;
  color: #1B8ADD ;
  text-align: left;
  margin-bottom: 10px;
}

.devicesDetailedHistoryDeviceInfos tr {
  height:30px;
}

.devicesDetailedHistoryDeviceInfos tr td:first-child {
  width: 30%;
}

.devicesDetailedHistoryTable > table {
  width: 100%;
  text-align: center;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.devicesDetailedHistoryTable::-webkit-scrollbar {
  display: none;
}
