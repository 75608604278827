@import "shared/styles/styleGlobal.scss";

.select-group-container {
    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.select-customize-input {
        width: 100%;
        .ant-select-selector {
            height: $select-height;
            border: $select-border;
            border-radius: $select-border-radius;
            .ant-select-selection-search input.ant-select-selection-search-input {
                color: $select-color;
            }
        }
        .ant-select-selection-item {
            color: $select-color;
        }
    }
    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.ant-select-open.select-customize-input {
        .ant-select-selection-item {
            color: #bfbfbf;
        }
    }
    .ant-select-arrow{
        top:60% !important;
    }
}

.ant-select-dropdown {
    border: 1px solid #338EF5 !important;
    border-top: none !important;
    border-radius: 0 0 5px 5px !important;
    margin-top: -1px !important;

    .ant-select-item.ant-select-item-option {
        color: $select-color;
    }

    .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
        font-weight: bold;
        color: $select-color;
        background-color: #ECF6FC;
    }
}