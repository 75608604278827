.complianceBadge{
    width: 30px;
    height: auto;
}
.serverModeConnexionContainer{
    border-color: #e0e0e0;
    background-color: #f3f3f3;
}

.eqButton{
    background-color: #1B8ADD ;
    color:white;
    width:100%;
    border-radius: 10px;
    height: 40px;
    margin:0 5px 0 5px;
}