@import "shared/styles/styleGlobal.scss";

.select-site-container {

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.select-customize-input,
    .ant-select.ant-select-outlined.ant-select-multiple.ant-select-show-arrow.ant-select-show-search.select-customize-input {
        .ant-select-selector {
            height: $select-height;
            border: $select-border;
            border-radius: $select-border-radius;

            .ant-select-selection-search input.ant-select-selection-search-input {
                color: $select-color;
            }
        }

        .ant-select-selection-item {
            color: $select-color;
        }
    }
    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.ant-select-open.select-customize-input,
    .ant-select.ant-select-outlined.ant-select-multiple.ant-select-show-arrow.ant-select-show-search.ant-select-open.select-customize-input {
        .ant-select-selection-item {
            color: #bfbfbf;
        }
    }
}

.ant-select-dropdown {
    border: 1px solid #338ef5 !important;
    border-top: none !important;
    border-radius: 0 0 5px 5px !important;
    margin-top: -1px !important;

    .ant-select-item-option-content {
        color: $select-color;
    }
}