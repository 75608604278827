.equimentManageModalButton{
    color:#174A84;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    cursor: pointer;
}
.eqButton{
    background-color: #1B8ADD ;
    color:white;
    width:100%;
    border-radius: 10px;
    height: 40px;
    margin:0 5px 0 5px;
}
