.input {
    .input-wrapper {
        border-radius: 5px;
        color: #1B8ADD;
        text-align: left;
        vertical-align: middle;
        padding: 5px;
        overflow: hidden;
        border: solid 1px #A8C4E5;
        background-color: #F7F9FC;
        margin-bottom: 10px;
    }
    .input-wrapper:focus-within {
        box-shadow: 0 0 0 2px #A8C4E5; /* Apply box-shadow when input is focused */
      }
    input::placeholder {
        color: grey
    }

    input {
        all: unset;
        width: 100%;
        margin-right: 10px;
    }

    .input-label {
        color: #394761;
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
    }
}

#form .input .input-wrapper {
    border-radius: 5px;
    color: #1B8ADD;
    text-align: left;
    vertical-align: middle;
    padding: 5px;
    overflow: hidden;
    border: solid 1px #338EF5;
    background-color: #F7F9FC;
    margin-bottom: 10px;
}

#form .input-label {
    color: #338EF5;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
}

 