.addBadgeButton  {
  cursor: pointer;
  padding: 0 10px 0 10px;
  color: #7d7d7d;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.addBadgeButtonLabel {
  margin-top: 3px;
  font-size: 10px;
  line-height: 11px;
  font-weight: 600;
}


 