@import "shared/styles/styleGlobal.scss";

.entity-infos-modal-form {
    .ant-form-item {
        label {
            color: #338EF5 !important;
            font-size: 14px !important;
            font-weight: bold !important;
            text-transform: capitalize !important;
        }
    }
}

.entity-infos-modal-form {
    .input .input-wrapper {
        border-radius: 5px;
        color: #1B8ADD;
        text-align: left;
        vertical-align: middle;
        padding: 5px;
        overflow: hidden;
        border: solid 1px #338EF5;
        background-color: #F7F9FC;
        margin-bottom: 0;

    }

    .ant-form {
        .ant-form-item {
            label {
                color: #338EF5 !important;
                font-size: 14px !important;
                font-weight: bold !important;
                text-transform: capitalize !important;
            }

            // .ant-input {
            //     box-sizing: border-box;
            //     margin: 0;
            //     padding: 0;
            //     font-variant: tabular-nums;
            //     list-style: none;
            //     font-feature-settings: 'tnum';
            //     position: relative;
            //     display: inline-block;
            //     min-width: 0;
            //     padding: 4px 11px;
            //     color: #1B8ADD;
            //     font-size: 14px;
            //     background-color: #fff;
            //     background-image: none;
            //     border: 1px solid #338EF5;
            //     border-radius: 5px;
            //     transition: all 0.3s;

            // }
        }
    }

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.select-customize-input {
        .ant-select-selector {
            height: $select-height;
            border: $select-border;
            border-radius: $select-border-radius;
            background-color: #F7F9FC;

            .ant-select-selection-search input.ant-select-selection-search-input {
                color: $select-color;
            }
        }

        .ant-select-selection-item {
            color: $select-color;
        }

        .ant-select-arrow {
            top: 60% !important;
            color: $select-color;
        }
    }

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-open.select-customize-input {
        .ant-select-selection-item {
            color: #bfbfbf;
        }
    }

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.select-customize-input.ant-select-disabled {
        .ant-select-selector {
            height: $select-height;
            border-color: #bfbfbf;
            border-radius: $select-border-radius;

            .ant-select-selection-search input.ant-select-selection-search-input {
                color: #bfbfbf;
            }

            .ant-select-selection-item {
                color: #bfbfbf;
            }
        }

        .ant-select-disabled.ant-select-arrow {
            top: 60% !important;
            color: #bfbfbf;
        }
    }

    .ant-picker {
        height: $select-height;
        border: $select-border;
        border-radius: $select-border-radius;
        background-color: #F7F9FC;
        color: $select-color;
    }
}