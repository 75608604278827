.alert {
  position: absolute;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  width: fit-content;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    /* Légèrement plus foncé au survol */
  }

  #alert-icon {
    margin-right: 12px;
  }

  #alert-content {
    flex: 1;

    #alert-description {
      font-weight: bold;
    }
  }

  div {
    cursor: pointer;
    font-weight: bold;

    &:hover {
      color: red;
      /* Couleur rouge pour la croix au survol */
    }
  }
}

.alert.hidden {
  display: none;
}

.alert.success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert.info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert.warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert.error {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  font-weight: bold;
  color: #333;
  font-size: 18px;
}

.alert.topLeft {
  top: 10px;
  left: 10px;
}

.alert.topRight {
  top: 10px;
  right: 10px;
}

.alert.bottomLeft {
  bottom: 10px;
  left: 10px;
}

.alert.bottomRight {
  bottom: 10px;
  right: 10px;
}

.alert.middle {
  top: 50%;
  left: 50%
}