@font-face {
    font-family: "Poppins-Black";  
    src: local("Poppins-Black"),
      url("../assets/fonts/poppins/Poppins-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-BlackItalic";   
    src: local("Poppins-BlackItalic"),
      url("../assets/fonts/poppins/Poppins-BlackItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-Bold";   
    src: local("Poppins-Bold"),
      url("../assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-ExtraBold";   
    src: local("Poppins-ExtraBold"),
      url("../assets/fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-BoldItalic";   
    src: local("Poppins-BoldItalic"),
      url("../assets/fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-ExtraBoldItalic";   
    src: local("Poppins-ExtraBoldItalic"),
      url("../assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-ExtraLight";   
    src: local("Poppins-ExtraLight"),
      url("../assets/fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-ExtraLightItalic";   
    src: local("Poppins-ExtraLightItalic"),
      url("../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-Italic";   
    src: local("Poppins-Italic"),
      url("../assets/fonts/poppins/Poppins-Italic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-Light";   
    src: local("Poppins-Light"),
      url("../assets/fonts/poppins/Poppins-Light.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-LightItalic";   
    src: local("Poppins-LightItalic"),
      url("../assets/fonts/poppins/Poppins-LightItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-Medium";   
    src: local("Poppins-Medium"),
      url("../assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-MediumItalic";   
    src: local("Poppins-MediumItalic"),
      url("../assets/fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-Regular";   
    src: local("Poppins-Regular"),
      url("../assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-SemiBold";   
    src: local("Poppins-SemiBold"),
      url("../assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-SemiBoldItalic";   
    src: local("Poppins-SemiBoldItalic"),
      url("../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-Thin";   
    src: local("Poppins-Thin"),
      url("../assets/fonts/poppins/Poppins-Thin.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-ThinItalic";   
    src: local("Poppins-ThinItalic"),
      url("../assets/fonts/poppins/Poppins-ThinItalic.ttf") format("truetype");
  }