@import 'shared/styles/colors.scss';

.devicesLastHistoryTable>table {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.devicesLastHistoryTable::-webkit-scrollbar {
  display: none;
}

.codeEllips {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;

  &.codeOk {
    background-color: $colorSuccess_7;
  }

  &.codeError {
    background-color: $colorError_6;
  }

  &.codeNotInstalled {
    background-color: $colorPrimary_6;
  }
}