//flex
.flex-display {
    display: flex;
}

.flex-display-inline {
    display: inline-flex;
}

//flex direction
.flex- {
    &row {
        flex-direction: row;

        &-reverse {
            flex-direction: row-reverse;
        }
    }

    &column {
        flex-direction: column;

        &-reverse {
            flex-direction: column-reverse;
        }
    }

}

//justify content
.justify-content- {
    &start {
        justify-content: start;
    }

    &end {
        justify-content: end;
    }

    &center {
        justify-content: center;
    }

    &around {
        justify-content: space-around;
    }

    &between {
        justify-content: space-between
    }

    &evenly {
        justify-content: space-evenly;
    }
}

//align-items
.align-items- {
    &start {
        align-items: flex-start;
    }

    &end {
        align-items: flex-end;
    }

    &center {
        align-items: center;
    }

    &baseline {
        align-items: baseline;
    }

    &stretch {
        align-items: stretch;
    }
}

//align self
.align-self- {
    &start {
        align-self: flex-start;
    }

    &end {
        align-self: flex-end;
    }

    &center {
        align-self: center;
    }

    &baseline {
        align-self: baseline;
    }

    &stretch {
        align-self: stretch;
    }
}

//align content
.align-content- {
    &start {
        align-content: flex-start;
    }

    &end {
        align-content: flex-end;
    }

    &center {
        align-content: center;
    }

    &between {
        align-content: space-between;
    }

    &-around {
        align-content: space-around;
    }

    &stretch {
        align-content: stretch;
    }
}

//flex wrap
.flex- {
    &wrap {
        flex-wrap: wrap;
    }

    &nowrap {
        flex-wrap: nowrap;
    }

    &wrap-reverse {
        flex-wrap: wrap-reverse;
    }
}

//flex order
.flex-order- {
    &first {
        order: -1;
    }

    &0 {
        order: 0;
    }

    &1 {
        order: 1;
    }

    &2 {
        order: 2;
    }

    &3 {
        order: 3;
    }

    &4 {
        order: 4;
    }

    &-5 {
        order: 5;
    }

    &last {
        order: 6;
    }
}

//flex grow
.flex-grow- {
    &0 {
        flex-grow: 0;
    }

    &1 {
        flex-grow: 1;
    }
}

//flex shrink
.flex-shrink- {
    &0 {
        flex-shrink: 0;
    }

    &1 {
        flex-shrink: 1;
    }
}