.transactionDetailsForm {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    color: #013D7B;
    padding: 20px;
}

.transactionDetailsForm table tr td span {
    margin-right: 50px;
}

.transactionDetailsForm table tr {
    width: 200px;
    font-size: 12px;
    font-weight: bold;
    color: #1B8ADD;
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;

}

.sale-details-tables {
    margin: 15px 0 25px 0;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
    }

    td {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #e5e5e5;
        color: #6D6E70;
        padding: 0 5px;
        vertical-align: middle;
        font-size: 1em;
        height: 1.6em; 
    }

    th {
        font-size: 0.9em;
        text-align: left;
        font-weight: bold;
        border: none;
    }
}

.sale-details-tables th:first-child,
.sale-details-tables td:first-child {
    width: 50%;
}

.sale-details-tables th:not(:first-child),
.sale-details-tables td:not(:first-child) {
    width: 25%;
}

.sale-details-tables td:last-child {
    text-align: right;
}
