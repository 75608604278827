@import "shared/styles/styleGlobal.scss";

.tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.tab-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.tab-list li {
    margin-right: $tabs-header-element-margin-right;
    min-width: 100px;
}

.tab-list li:last-child {
    margin-right: 0;
}

.tab-list a {
    display: block;
    padding: $tabs-header-element-padding;
    background-color: $tabs-header-bg-color;
    line-height: $tabs-header-label-line-height;
    height: $tabs-header-height;
    border-radius: $tabs-header-element-border-radius;
    font-weight: $tabs-header-label-font-weight;
    font-size: $tabs-header-label-font-size;
    color: $tabs-header-label-color;
    text-transform: $tabs-header-label-text-transform;
    opacity: 0.3;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-list a:hover {
    opacity: 0.5;
    color: $tabs-header-label-color;
}

.tab-list a.tab-active {
    opacity: 1;
}

.tab-list a.tab-disabled {
    cursor: not-allowed
}

.tab-content {
    padding: $tabs-content-padding;
    background: $tabs-content-bg-color;
    border: $tabs-content-border;
    width: 100%;
    border-radius: $tabs-content-border-radius;
    //overflow-y: scroll;

}