.devicesListEquipmentInfos {
    text-align: Left;
    font-size: 12px;
    line-height: 20px;
    font-family: Poppins-SemiBold;
    color: #1B8ADC;
    padding: 15px 0px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
  }
  .devicesFilter{
      text-align: Left;
      font-size: 12px;
      line-height: 20px;
      font-family: Poppins-SemiBold;
      color: #1B8ADC;
      margin-top: 10px;
      margin-right:10px
  }
  .equipmentDevicesListCounter {
    width: 200px;
    height: 35px;
    background: #338ef5;
    color: #fff;
    text-align: left;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .deviceList {
    height: 595px;
    padding:10px 15px 10px 15px; 
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .deviceList::-webkit-scrollbar {
      display: none;
  }
  .deviceList>table>tbody>tr{
      border-bottom: 0.5px dashed rgb(194, 204, 214);
      cursor: pointer;
      height: 60px;
  }
  .deviceList>table{
      width: 100% ;
  }
  .deviceRowName{
      text-align: left;
      color:slategray;
  }
  .deviceRowType{
      color:red;
      font-size: 12px;
  }
  .deviceRowStatusIcon{
      text-align: center;
      color:var(--secondary);
  }
  .deviceRowDate{
      text-align: right;
      color:slategray;
      font-size: 12px;
      font-style: italic;
  }
