.badgeInfosTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 5px;
}



.badgeListTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 5px;
}



tr.table-row-clicked ,tr.table-row-clicked:hover, .table.table-stripped tr.table-row-clicked, .table.table-stripped tr.table-row-clicked:hover, tr.table-row-clicked .table-column-accent{ 
    background-color: #95CA00;
}
